<script setup lang="ts">
import { HomeBanners, FirstScreenBanners } from "~/types";

const { gamesWithWeekGame, hotGames, lastGames, jackpotGames, isShowAdventureBanner, banners, jackpots, seoData } =
	useHomePage();

useHead({
	title: () => seoData.value?.seoTitle || "",
	meta: [
		{
			name: "description",
			content: () => seoData.value?.seoDescription
		},
		{ name: "keywords", content: () => seoData.value?.seoKeywords || "" }
	]
});

const isGuest = useIsGuest();

const { t } = useT();
const { isDesktop } = useDevice();
const { data: appInitData } = useAppInitData();
</script>
<template>
	<NuxtLayout>
		<div :class="['page', { 'is-logged': !isGuest }]">
			<OHomeBanners v-if="banners?.length && banners.length > 0" :banners="banners as FirstScreenBanners" />
			<OHomeGames
				v-if="lastGames?.length && !isGuest"
				:games="lastGames"
				:title="t('Recently Played')"
				icon="home/recent"
				category="recent-games"
				:rows-per-slide="isDesktop ? 1 : 2"
			/>
			<OHomeGames
				v-if="gamesWithWeekGame?.length"
				:games="gamesWithWeekGame"
				:title="t('Must try')"
				icon="home/must-try"
				category="popular-games"
				:rows-per-slide="isDesktop ? 1 : 2"
			/>
			<ClientOnly>
				<OHomeActivityFeed />
			</ClientOnly>

			<div v-if="isGuest" class="wheels-banners">
				<OHomeBannerWheel v-if="appInitData?.isShowDailyWheelForGuest" />
				<OHomeBannerAdventure v-if="isShowAdventureBanner" />
			</div>
			<OHomeSimpleSteps v-if="isGuest" />
			<OHomePromotions />
			<OHomeGames
				v-if="hotGames?.length && hotGames.length > 0 && !appInitData?.isGuest"
				:games="hotGames"
				:title="t('Hot games')"
				icon="home/hot"
				category="hot-games"
				:rows-per-slide="isDesktop ? 1 : 2"
			/>
			<OHomeWhyUs v-if="isGuest" />
			<OHomeJackpot
				v-if="jackpots"
				:game-mode="appInitData?.gameMode"
				:jackpots="jackpots"
				:games="jackpotGames.slice(0, 4)"
			/>
			<OHomeBannerFooter
				v-if="isGuest && banners?.length && banners.length > 0"
				:banner="banners?.[0] as HomeBanners[number]"
			/>
		</div>
	</NuxtLayout>
</template>
<style lang="scss" scoped>
.page {
	display: flex;
	flex-direction: column;

	@include media-breakpoint-down(md) {
		gap: 0;

		&.is-logged {
			position: relative;
			padding-top: 8px;
		}
	}
}

.wheels-banners {
	display: flex;
	flex-direction: column;
	gap: gutter(4);
	padding: gutter(3) 0;

	@include media-breakpoint-down(md) {
		padding: gutter(2.5) 0;
		gap: gutter(3);
	}
}
</style>
